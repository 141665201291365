<template>
  <div class="component-upload-image">
    <el-upload class="avatar-uploader" list-type="picture-card" action="#" :file-list="fileList" :on-exceed="handleExceed"
      :http-request="upload" :on-remove="handleRemove" :on-preview="handlePreview" v-loading="loading">
      <i class="el-icon-plus"></i>
    </el-upload>
    <div slot="tip" class="el-upload__tip">
      请上传图片
    </div>
    <el-dialog :visible.sync="dialogVisible" append-to-body>
      <img width="90%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </div>
</template>

<script>
import {
  detailUpload,
  uploadFile,
  delUpload,
  downloadFile
} from '@/api/store/file'

export default {
  data() {
    return {
      fileList: [],
      fileListArr: [],
      fileIdList: [],
      accept: '.png,.jpg,.jpeg,.gif,.bmp',
      dialogImageUrl: '',
      dialogVisible: false,
      loading: false
    }
  },
  props: {
    fileIds: Array,
    limit: {
      type: Number,
      default: 10
    },
    pageType: String
  },
  methods: {

    upload({ file }) {
      // 上传方法
      const formData = new FormData()
      formData.append('file', file)
      this.loading = true;
      this.loading = false
      uploadFile(formData)
        .then((res) => {
          if (res.data.success) {
            file.guid = res.data.result.guid
            this.fileListArr.push(file)
            this.syncFileIdList()
          } else {
            this.$msgError(res.data.message)
          }
          this.loading = false
        })
        .catch((error) => {
          console.log(error)
          this.loading = false
        })
    },

    async handleRemove(file) {
      // 移除文件
      this.$confirm('是否确认删除名称为"' + file.name + '"的文件吗?', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {

        this.fileList.forEach((item, index) => {
          if (item.guid == file.guid) {
            this.fileList.splice(index, 1)
            this.loading = true
            delUpload({ guid: item.guid }).then((response) => {
              this.syncFileIdList()
              this.loading = false
            })
          }
        })
      })
    },
    async handlePreview(file) {
      // 预览
      let data = {
        guid: file.guid
      }
      this.loading = true
      downloadFile(data).then((res) => {
        //download
        let url = window.URL.createObjectURL(new Blob([res.data]))
        let link = document.createElement('a')
        link.style.display = 'none'
        link.href = url
        // link.setAttribute("download", file.name); // 自定义下载文件名（如exemple.txt）
        // document.body.appendChild(link);
        // link.click();

        //preview
        this.dialogImageUrl = url
        this.dialogVisible = true
        this.loading = false
      })
    },
    async handleDownload(file) {
      // 下载
      let data = {
        guid: file.guid
      }
      this.loading = true
      downloadFile(data).then((res) => {
        let url = window.URL.createObjectURL(new Blob([res.data]))
        let link = document.createElement('a')
        link.style.display = 'none'
        link.href = url
        link.setAttribute('download', file.name) // 自定义下载文件名（如exemple.txt）
        document.body.appendChild(link)
        link.click()
        this.loading = false
      })
    },
    handleExceed() {
      this.$message.warning('超出上传数量限制')
    },
    syncFileIdList() {
      this.fileIdList = this.fileListArr.map((item) => item.guid)
      this.$emit('syncFileIds', this.fileIdList)
    }
  },
  watch: {
    fileIds: {
      immediate: true,
      handler(arr) {
        this.fileIdList = arr
        if (arr.length !== this.fileListArr.length) {
          this.fileListArr = []
          arr.forEach((val) => {
            let data = {
              guid: val
            }
            this.loading = true
            detailUpload(data).then((res) => {

              if (res.data.success) {
                let file = {
                  name: res.data.result.fileName,
                  guid: res.data.result.guid
                }
                this.fileListArr.push(file)
              } else {
                let file = {
                  name: '',
                  guid: val,
                  url: ''
                }
                this.fileListArr.push(file)
              }
              this.loading = false
            })
          })
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.el-upload__tip {
  padding-top: 10px;
  font-size: 12px;
  color: #c7cbd2;
  margin-top: 0;
}
</style>

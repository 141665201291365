<template>
  <div class="app-container">
    <div class="top_box">
      <ImageUpload :fileIds="attachments" @syncFileIds="saveFileIds"></ImageUpload>
    </div>
    <el-row v-if="!messages" v-loading="loading" element-loading-text="拼命识别中..." style="margin-top: 100px;"></el-row>
    <div class="showarea" v-else>
      <p style="padding-bottom: 20px;text-align: center;color: #1890ff;">以下为本图片识别出的内容</p>
      {{ messages }}
    </div>
  </div>
</template>
<script>
import { ocrTranscription } from '@/api/ai/ocr'
import ImageUpload from '@/components/ocrUpload/index'
export default {
  name: 'AiChat',
  data() {
    return {
      attachments: [],
      messages: '',
      pageType: 'ocr',
      loading: false
    }
  },
  mounted() { },
  methods: {
    saveFileIds(ids) {
      this.loading = true
      this.attachments = ids
      ocrTranscription({
        guid: ids.toString(),
        language: 'ch'
      }).then((res) => {
        if (res.data.success) {
          this.messages = JSON.parse(res.data.result).result
        } else {
          this.$msgError(res.data.message)
        }
        this.loading = false
      })
    },
  },
  components: {
    ImageUpload // 注册上传组建
  }
}
</script>
<style scoped>
p {
  padding: 0;
  margin: 0;
}

.app-container {
  overflow: hidden;
  position: relative;
  overflow: hidden;
  height: 100%;
}

.top_box {
  margin: 10px 0;
  border-bottom: 1px solid #DCDFE6;
  padding-bottom: 10px;
}

.showarea {
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  padding: 20px;
  line-height: 24px;
  background: #f8f8f8;
}

@media only screen and (max-width: 768px) {
  [class*="top_box"] {
    width: 100%;
    background: #fff;
  }

  [class*="showarea"] {
    width: 100%;
  }
}
</style>
